import React, { useState, createContext, useCallback } from "react";
import { fuelPriceApi } from "../api/fuelPrice-api";

/**
 * Fuel list context
 */
export const FuelPriceContext = createContext();

/**
 * Fuel list provider
 */
export const FuelPriceProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [fuelPrice, setFuelPrice] = useState([]);

  const getFuelPriceApi = async (values) => {
    setLoading(true);
    const response = await fuelPriceApi.getFuelPriceList({
      limit: values?.limit ? values.limit : 10000,
      offset: values?.offset ? values.offset : 0,
      filter: {
        ...values?.filter,
      },
      sorting: {
        fieldName: "",
        direction: "",
      },
    });
    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      setFuelPrice(response?.data?.data);
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };
  const getFuelPriceDailyUpdate = async (id) => {
    setLoading(true);
    const response = await fuelPriceApi.getDailyPriceUpdate(id);

    if (response?.error) {
      setLoading(false);
    }

    if (response?.data?.data) {
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };

  const contextValue = {
    loading,
    setLoading,
    fuelPrice,
    setFuelPrice,
    getFuelPriceApi: useCallback((request) => {
      getFuelPriceApi(request);
    }, []),
  };

  return (
    <FuelPriceContext.Provider value={contextValue}>
      {children}
    </FuelPriceContext.Provider>
  );
};
