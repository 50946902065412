import { useEffect, useState } from "react";
import { proposedCalculation } from "../calculationProposed";
import {
  sumEquityGains,
  sumFuelCosts,
  sumMaintenanceCosts,
  sumYearValues,
} from "../../utils/methods";
import { extractValue } from "../commonFunction";

let dataFiscalYear = [
  { fiscalYear: "Current" },
  { fiscalYear: "Year1" },
  { fiscalYear: "Year2" },
  { fiscalYear: "Year3" },
  { fiscalYear: "Year4" },
  { fiscalYear: "Year5" },
];

const useFleetManagement = (
  currentFleetSize,
  replacementList,
  rowIndex,
  monthlyPaymentAmount,
  purchaseFinance,
  cashOutlay,
  newVehicle,
  maintanance,
  fuelListFilter,
  totalFuelSpend,
  fleetList,
  companyNameHeader,
  cycleFuelYearCost,
  cycleFuelYearCost2,
  cycleFuelYearCost3,
  cycleFuelYearCost4,
  cycleFuelYearCost5
) => {
  const [rowData, setRowData] = useState([]);
  const [proposedCycleYear, setProposedCycleYear] = useState(0);
  const [savingTotal, setSavingTotal] = useState(0);

  useEffect(() => {
    let yearValueList = [];
    if (replacementList?.length > 0) {
      const result = sumYearValues(replacementList);
      let proposedCycle = proposedCalculation(replacementList);
      let avgProposedCycle = proposedCycle / replacementList?.length;
      let roundedProposedCycle = Math.round(avgProposedCycle);
      setProposedCycleYear(roundedProposedCycle);

      yearValueList.push(
        result?.year1Value,
        result?.year2Value,
        result?.year3Value,
        result?.year4Value,
        result?.year5Value
      );
    }
    if (currentFleetSize > 0) {
      dataFiscalYear?.map((items, index) => {
        items["fleetSize"] = currentFleetSize;
      });
    }

    let monthlyLease = [];
    let currentPayme = 0;
    if (monthlyPaymentAmount > 0) {
      let dataMonthlyLease1 = 0;
      let dataMonthlyLease2 = 0;
      let dataMonthlyLease3 = 0;
      let dataMonthlyLease4 = 0;
      let dataMonthlyLease5 = 0;
      if (purchaseFinance > 0) {
        if (rowIndex == 0) {
          dataMonthlyLease1 = (monthlyPaymentAmount / purchaseFinance) * 12;
        } else if (rowIndex == 1) {
          dataMonthlyLease2 = (monthlyPaymentAmount / purchaseFinance) * 13;
        } else if (rowIndex == 2) {
          dataMonthlyLease3 = (monthlyPaymentAmount / purchaseFinance) * 14;
        } else if (rowIndex == 3) {
          dataMonthlyLease4 = (monthlyPaymentAmount / purchaseFinance) * 15;
        } else if (rowIndex == 4) {
          dataMonthlyLease5 = (monthlyPaymentAmount / purchaseFinance) * 16;
        }
      } else {
        dataFiscalYear?.map((items, index) => {
          items["purchasedFinancial"] = 0;
        });
      }
      if (cashOutlay <= 0) {
        dataFiscalYear?.map((items, index) => {
          items["cashOutlay"] = "$ 0";
        });
      }
      if (currentPayme <= 0) {
        dataFiscalYear?.map((items, index) => {
          items["currentPayment"] = "$ 0";
        });
      }

      monthlyLease.push(
        { cyclingPayment: `$ 0` },
        { cyclingPayment: `$ ${dataMonthlyLease1}` },
        { cyclingPayment: `$ ${dataMonthlyLease2}` },
        { cyclingPayment: `$ ${dataMonthlyLease3}` },
        { cyclingPayment: `$ ${dataMonthlyLease4}` },
        { cyclingPayment: `$ ${dataMonthlyLease5}` }
      );
    }

    const equityResult = sumEquityGains(newVehicle);
    let equityGain = [
      {
        equityGain: "$ 0",
      },
      {
        equityGain: `$${equityResult?.equity1Gain}`,
      },
      {
        equityGain: `$${equityResult?.equity2Gain}`,
      },
      {
        equityGain: `$${equityResult?.equity3Gain}`,
      },
      {
        equityGain: `$${equityResult?.equity4Gain}`,
      },
      {
        equityGain: `$${equityResult?.equity5Gain}`,
      },
    ];
    if (equityGain?.length > 0) {
      dataFiscalYear?.map((items, index) => {
        items["vehicleEquity"] = equityGain[index]?.equityGain;
      });
    }

    const maintenanceResult = sumMaintenanceCosts(maintanance);

    const maintananceData1 = [
      { maintanance: `$ ${maintenanceResult?.sumMaintenance}` },
      { maintanance: `$ ${maintenanceResult?.cycleYear1}` },
      { maintanance: `$ ${maintenanceResult?.cycleYear2}` },
      { maintanance: `$ ${maintenanceResult?.cycleYear3}` },
      { maintanance: `$ ${maintenanceResult?.cycleYear4}` },
      { maintanance: `$ ${maintenanceResult?.cycleYear5}` },
    ];
    if (maintananceData1?.length > 0) {
      dataFiscalYear?.map((items, index) => {
        items["maintenance"] = maintananceData1[index]?.maintanance;
      });
    }
    if (monthlyLease?.length > 0 && !rowIndex) {
      dataFiscalYear?.map((items, index) => {
        items["cyclingPayment"] = monthlyLease[index]?.cyclingPayment;
      });
    }
    if (rowIndex) {
      dataFiscalYear[rowIndex]["cyclingPayment"] =
        monthlyLease[rowIndex]?.cyclingPayment;
    }

    const resultFuelCost = sumFuelCosts(fuelListFilter);
    let AnalysisFuel = [
      { fuel: `$ ${totalFuelSpend}` },
      { fuel: `$ ${resultFuelCost?.fuelYear1}` },
      { fuel: `$ ${resultFuelCost?.fuelYear2}` },
      { fuel: `$ ${resultFuelCost?.fuelYear3}` },
      { fuel: `$ ${resultFuelCost?.fuelYear4}` },
      { fuel: `$ ${resultFuelCost?.fuelYear5}` },
    ];

    if (AnalysisFuel?.length > 0) {
      dataFiscalYear?.map((items, index) => {
        items["fuel"] = AnalysisFuel[index]?.fuel;
      });
    }
    if (yearValueList?.length > 0) {
      const years = fleetList.map((item) => item.year);
      const uniqueYears = new Set(years);
      const numberOfDifferentYears = uniqueYears.size;
      dataFiscalYear?.map((items, index) => {
        if (index === 0) {
          items["annualNeeds"] = parseFloat(
            Math.round(currentFleetSize / numberOfDifferentYears)
          );
        } else {
          items["annualNeeds"] = yearValueList[index - 1];
        }
      });
    }
    dataFiscalYear?.map((items, index) => {
      // Extract values and convert them to numbers
      const cycllePayment = Number(extractValue(items, "cyclingPayment"));
      const cashOut = Number(extractValue(items, "cashOutlay"));
      const current = Number(extractValue(items, "currentPayment"));
      const maintain = Number(extractValue(items, "maintenance"));
      const fuels = Number(extractValue(items, "fuel"));

      const sum = cycllePayment + cashOut + current + maintain + fuels;
      items["fleetSpeed"] = "$" + sum;
    });
    dataFiscalYear?.map((items, index) => {
      const spend = Number(extractValue(items, "fleetSpeed"));
      const equity = Number(extractValue(items, "vehicleEquity"));
      const currentYear = Number(extractValue(dataFiscalYear[0], "fleetSpeed"));
      const currentMinus = currentYear - spend;
      if (!dataFiscalYear.includes("Current")) {
        items["savings"] = currentMinus + equity;
      } else {
        items["savings"] = 0;
      }
    });
    let savingSum = 0;
    dataFiscalYear?.map((items, index) => {
      savingSum += Number(items?.savings);
    });

    setSavingTotal(savingSum);
    setRowData([...dataFiscalYear]);
  }, [
    companyNameHeader,
    currentFleetSize,
    replacementList,
    rowIndex,
    cycleFuelYearCost,
    cycleFuelYearCost2,
    cycleFuelYearCost3,
    cycleFuelYearCost4,
    cycleFuelYearCost5,
    newVehicle,
    fuelListFilter,
  ]);

  return {
    rowData,
    proposedCycleYear,
    savingTotal,
  };
};

export default useFleetManagement;
