import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { AgGridReact } from "ag-grid-react";
import { UploadCsvDataColumn } from "../common/constants/gridRowColumn";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import sampleCSV from "../assets/csv/SampleFleetList.csv";
import {
  useDealer,
  useDriveTrain,
  useFuelList,
  useModel,
  useTypeList,
} from "../common/hooks";
import { checkbox, typeConstant } from "../common/constants/commonFunction";
import { PopupMessage } from "../common/constants/popupMessage";
import { CsvUploadErrors } from "../common/constants/csvUploadError";
import { showToast } from "./Toast";
import {
  csvFileToArray,
  escapeSpecialChars,
  escapeSpecialCharsCompany,
  xlsxFileToArray,
} from "../common/utils/excelUpload";
import { SelectedModels } from "./SelectedModels";

export const FleetCsvUpload = ({
  handleClose,
  open,
  classes,
  defaultColDef,
  handleRowSelection,
  addButtonDisabled,
  setRowDataCsv,
  columnFinal,
  setAddButtonDisabled,
  rows,
  setRows,
  file,
  setFile,
}) => {
  const {
    closetMatchModel,
    selectClosetModel,
    setSelectClosetModel,
    newRowData,
    setNewRowData,
    newRowIndexData,
    isClosetModel,
    setSelectedModel,
  } = useModel();
  const cellEditorSelector = (params) => {
    return {
      component: SelectedModels,
    };
  };

  const { loadingFleetFile, uploadCSVDealerApi } = useDealer();
  const { fuelList, getFuelListApi } = useFuelList();
  const { driveTrainList, getDriveTrainListApi } = useDriveTrain();
  const { typeList, getTypeListApi } = useTypeList();
  const { model, getModel } = useModel();
  const [columns, setColumns] = useState([]);
  const [columnFinal1, setColumnFinal1] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectIndex, setSelectIndex] = useState([]);
  const [gridApi, setGridApi] = useState(null);

  useEffect(() => {
    if (columns?.length > 0) {
      columns?.map((item) => {
        item["width"] = 160;
        return true;
      });
    }
  }, [columns]);
  // useEffect(() => {
  //   getModel({ limit: 10000, page: 0 });
  // }, []);

  useEffect(() => {
    if (isClosetModel) {
      newRowData?.map((items) => {
        isClosetModel?.map((item) => {
          if (items?.id == item?.id) {
            items["selectedModelRow"] = item?.selectedModelRow;
          }
        });
      });
      setNewRowData(newRowData);
    }
  }, [isClosetModel]);
  useEffect(() => {
    // if (modelData?.length > 0) {
    let actionColumn = {
      headerName: "Closest Match Models",
      field: "language",
      cellRenderer: SelectedModels,
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        dataValue: newRowData,
        cellRenderer: SelectedModels,
      },
    };
    setColumnFinal1([checkbox, ...UploadCsvDataColumn]);
    // }
  }, [newRowData, UploadCsvDataColumn, isClosetModel]);

  useEffect(() => {
    newRowData?.map((item, index) => {
      item["id"] = index + 1;
    });
    if (newRowData?.length > 0) {
      closetMatchModel(newRowData);
    }
  }, [newRowData]);

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };
  const downloadCsv = () => {
    const link = document.createElement("a");
    link.href = sampleCSV;
    link.setAttribute("download", "Sample Fleet List.csv");

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  const handleCellValueChanged = (event) => {
    const newValue = event.newValue;
    const rowData = event.data;
    const field = event.colDef.field;
  };

  useEffect(() => {
    getFuelListApi();
    // getDriveTrainListApi();
    getTypeListApi();
    setSelectedModel(null);
  }, []);

  const handleAddButton = async (e) => {
    e.stopPropagation();
    setAddButtonDisabled(true);
    if (!file) {
      showToast(PopupMessage.selectFile);
      return;
    }
    let { rowData, error } = await CsvUploadErrors(newRowData, columnFinal);
    rowData?.map((items, index) => {
      fuelList?.map((item) => {
        if (item?.name === items?.fuel) {
          rowData[index].fuelId = item?.id;
        }
      });
    });
    rowData?.map((items, index) => {
      driveTrainList?.map((item) => {
        if (item?.name === items?.driveTrain) {
          rowData[index].driveTrainId = item?.id;
        }
      });
    });
    rowData?.map((items, index) => {
      typeList?.map((item) => {
        if (item?.name === items?.type) {
          rowData[index].typeId = item?.id;
        }
      });
    });
    let vinNumber = [];
    // rowData?.map((items, index) => {
    //   if (items?.vin) {
    //     const count = items?.vin?.length;
    //     if (count !== 17) {
    //       vinNumber.push({ lineNumber: index, vin: items?.vin });
    //     }
    //   }
    // });
    setRowDataCsv(rowData);
    if (error?.length == 0) {
      let typeId;
      let type = localStorage.getItem("type");
      if (type === typeConstant.dealer) {
        typeId = localStorage.getItem("typeId");
      }
      let payload;
      const newDataRow = escapeSpecialCharsCompany(newRowData);
      if (typeId) {
        payload = {
          dealerId: typeId,
          data: newDataRow,
        };
      } else {
        payload = {
          dealerId: null,
          data: newDataRow,
        };
      }
      // let payload = {
      //   ...newRowData,
      //   companyId: companyId,
      // };
      newDataRow?.map((items) => {
        if (!items?.selectedModel) {
          items["selectedModel"] = "";
        } else {
          items["selectedModel"] = items?.selectedModel?.model
            ? items?.selectedModel?.model
            : items?.selectedModel;
        }
      });
      if (vinNumber?.length > 0) {
        const error = vinNumber;
        const errorMessage = error
          .map(
            (error) =>
              `Line ${error.lineNumber}: VIN ${error.vin} is not valid.`
          )
          .join("\n");
        showToast(errorMessage);
        setAddButtonDisabled(false);
        return;
      } else {
        showToast(PopupMessage.uploadFile);
        await uploadCSVDealerApi(payload);
        setRowDataCsv([]);
        setSelectClosetModel("");
      }
      // navigation("/fleet-list");
    }
  };
  const fileToArray = async (file, type) => {
    if (type === "csv") {
      const text = await file.text();
      return csvFileToArray(text);
    } else if (type === "xlsx") {
      return xlsxFileToArray(file);
    } else {
      throw new Error("Unsupported file type");
    }
  };
  useEffect(() => {
    if (file) {
      const fileType = file.name.split(".").pop().toLowerCase();
      let type = "";
      if (fileType === "csv") {
        type = "csv";
      } else if (["xlsx", "xls"].includes(fileType)) {
        type = "xlsx";
      }

      fileToArray(file, type)
        .then(({ data, headers }) => {
          setRows(data);
          setNewRowData(data);
          setColumns(
            headers.map((header) => ({ field: header, headerName: header }))
          );
        })
        .catch((error) => console.error("Error processing file:", error));
    } else {
      setRows([]);
      setNewRowData([]);
      setColumns([]);
    }
  }, [file]);
  const frameworkComponents = {
    selectedModels: SelectedModels,
  };

  // Set up gridApi using onGridReady
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const goToRowIndex = (index) => {
    if (gridApi) {
      let indexValue = index == null ? 0 : index;
      gridApi.ensureIndexVisible(indexValue, "middle"); // Scrolls to the specified index
    }
  };
  const onFirstDataRendered = () => {
    goToRowIndex(newRowIndexData);
  };

  useEffect(() => {
    if (newRowIndexData) {
      goToRowIndex(newRowIndexData);
    }
  }, [newRowData, newRowIndexData]);

  // useEffect(() => {
  //   if (newRowData && newRowData?.length > 0) {
  //     console.log("newRowData;;;;;;;;;;;;;;;;", newRowData);
  //     setNewRowData(newRowData);
  //   } else {
  //     setNewRowData([]);
  //   }
  // }, []);
  // useEffect(() => {
  //   console.log("newRowData;;;;;;;;;;;;;;;", newRowData);
  //   if (newRowData && newRowData.length > 0) {
  //     setRows(newRowData);
  //   }
  // }, [newRowData]);
  return (
    <Drawer
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="sm"
      anchor="bottom"
      fullHeight
      style={{ top: "100px ", bottom: "56px !important" }}
      className="DrawerName"
    >
      <DialogTitle className={classes.dailogTitle} id="customized-dialog-title">
        Upload Fleet CSV / XLSX
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{ fontWeight: "bold", mr: 2, mt: 0, color: "#18333A" }}
            >
              CSV / XLSX File
            </Typography>
            <Box className={classes.uploadButton}>
              <form style={{ position: "relative" }}>
                <input
                  id="filePicker"
                  accept=".csv"
                  onChange={handleOnChange}
                  style={{
                    visibility: "hidden",
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "0",
                    bottom: "0",
                  }}
                  type={"file"}
                />
                <label
                  htmlFor="filePicker"
                  style={{
                    background: "#18333A",
                    color: "white",
                    padding: "8px 25px",
                    borderRadius: "5px",
                  }}
                >
                  Upload
                </label>
              </form>
            </Box>
          </Box>
          <Stack direction="row" spacing={2}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 1,
              }}
            >
              {/* <div className={classes.dotYes}></div> */}
              <Typography
                sx={{
                  background: "rgb(166 231 171)",
                  color: "black",
                  mr: 1,
                  fontSize: "14px",
                  padding: "2px 4px",
                }}
              >
                Yes
              </Typography>
              <label>Closest Match Available in Database</label>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* <div className={classes.dotNo}>No</div> */}
              <Typography
                sx={{
                  background: "rgb(239 208 180)",
                  color: "black",
                  padding: "2px 4px",
                  mr: 1,
                  fontSize: "14px",
                }}
              >
                No
              </Typography>
              <label>No Match Available in Database</label>
            </Box>
          </Stack>
        </Box>
        <Box
          sx={{
            height: "calc(100vh - 347px)",
            width: "100%",
          }}
        >
          <AgGridReact
            className="ag-theme-quartz"
            onRowSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRows = newRowData.filter((row) =>
                selectedIDs.has(row.id)
              );
              setSelectedRows(selectedRows);
            }}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            rowSelection="multiple"
            pagination={true}
            rowData={newRowData}
            defaultColDef={defaultColDef}
            columnDefs={columnFinal1}
            checkboxSelection
            onRowSelected={handleRowSelection}
            frameworkComponents={frameworkComponents}
            onCellValueChanged={(event) => handleCellValueChanged(event)}
            suppressRowClickSelection={true}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: "20px !important" }}>
        <Button
          variant="contained"
          className={classes.fleetListNewButton}
          disabled={addButtonDisabled}
          onClick={handleAddButton}
        >
          {loadingFleetFile ? "Uploading..." : "Add"}
        </Button>
        <Button
          href="#"
          variant="outlined"
          style={{
            color: "#18333A",
            fontWeight: "1000",
            border: "2px solid red",
          }}
          onClick={downloadCsv}
        >
          Download Sample File
        </Button>
        <Button
          variant="contained"
          className={classes.fleetListNewButton}
          onClick={handleClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Drawer>
  );
};
