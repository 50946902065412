const apiUris = {};

//Development URL

// const baseUri = "https://dev.fleet-analysis.com/AdminApi/api";
// export const baseURL = "https://dev.fleet-analysis.com/AdminApi/api";
// export const frontUrl = "https://dev.fleet-analysis.com";

//Old Server
// const baseUri = "https://nbscashflows.com/AdminApi/api";
// const baseUri = "http://3.27.28.123/api";
// export const baseURL = "http://3.27.28.123/api";
// export const baseURL = "https://nbscashflows.com/AdminApi/api";

//Production URL

const baseUri = "https://www.fleet-analysis.com/AdminApi/api";
export const baseURL = "https://www.fleet-analysis.com/AdminApi/api";
export const frontUrl = "https://www.fleet-analysis.com";

export const WebVersion = "Version 1.5.1";

const AUTHENTICATE_ROOT = `${baseUri}/Authentication`;
const authenticate = {
  loginUser: `${AUTHENTICATE_ROOT}/login`,
  verifyOtp: `${AUTHENTICATE_ROOT}/LoginWithOtp`,
};
apiUris.authenticate = authenticate;

const user = `${baseUri}/Users`;
const userApi = {
  getUser: `${user}/List`,
  createUser: `${user}/Create`,
  updateUser: `${user}/Update`,
  deleteUser: `${user}`,
  getbyId: `${user}`,
};
apiUris.userApi = userApi;

const company = `${baseUri}/Company`;
const companyApi = {
  getCompany: `${company}/List`,
  getCompanyById: `${company}`,
  addCompany: `${company}/Create`,
  updateCompany: `${company}/Update`,
  deleteCompany: `${company}`,
};
apiUris.companyApi = companyApi;

const dealer = `${baseUri}/Dealer`;
const dealerApi = {
  getDealer: `${dealer}/List`,
  getListDealer: `${dealer}/ListDealer`,
  getDealerById: `${dealer}`,
  deleteDealerById: `${dealer}`,
  createDealer: `${dealer}/Create`,
  editDealer: `${dealer}/Update`,
  uploadCsv: `${dealer}/UploadCSVData`,
  multipleFleetUpdate: `${dealer}/MultipleUpdate`,
  singleFleetUpdate: `${dealer}/UpdateCalculation`,
  manualCalculationUpdate: `${dealer}/ManualCalculation`,
  multipleManualCalculationUpdate: `${dealer}/MultipleManualCalculationUpdate`,
  autoMultipleManualCalculationUpdate: `${dealer}/AutoMultipleManualCalculationUpdate`,
};
apiUris.dealerApi = dealerApi;

const dealerCompany = `${baseUri}/DealerCompany`;
const dealerCompanyApi = {
  getDealerCompanyList: `${dealerCompany}/DealerCompanyList`,
  addDealerCompany: `${dealerCompany}/Create`,
  getDealerCompany: `${dealerCompany}/DealerCompanyList`,
  deleteDealerCompany: `${dealerCompany}`,
  editDealerCompany: `${dealerCompany}/Update`,
};
apiUris.dealerCompanyApi = dealerCompanyApi;

const model = `${baseUri}/Model`;
const ModelApi = {
  getModel: `${model}/List`,
  getModelById: `${model}`,
  deleteModelById: `${model}`,
  createModel: `${model}/Create`,
  editModel: `${model}/Update`,
  modelList: `${model}/ModelListByMakeAndYearId`,
  closetMatchModel: `${model}/GetClosetMachModule`,
  modelCount: `${model}/ModelCount`,
};
apiUris.ModelApi = ModelApi;

const make = `${baseUri}/Make`;
const makeApi = {
  getmake: `${make}/List`,
  getMakeById: `${make}`,
  deleteMakeById: `${make}`,
  createMake: `${make}/Create`,
  editMake: `${make}/Update`,
};
apiUris.makeApi = makeApi;

const newVehicle = `${baseUri}/NewVehicle`;
const newVehicleApi = {
  getNewVehicle: `${newVehicle}/NewVehicleList`,
  getNewVehicleById: `${newVehicle}`,
  deleteNewVehicleById: `${newVehicle}`,
  createNewVehicle: `${newVehicle}/Create`,
  editNewVehicle: `${newVehicle}/Update`,
  multipleNewVehicle: `${newVehicle}/UpdateMultiple`,
};
apiUris.newVehicleApi = newVehicleApi;

const year = `${baseUri}/Year`;
const yearApi = {
  getYear: `${year}/List`,
  getYearById: `${year}`,
  deleteYearById: `${year}`,
  createYear: `${year}/Create`,
  editYear: `${year}/Update`,
};
apiUris.yearApi = yearApi;

const fleetList = `${baseUri}/Fleet`;
const fleetListApi = {
  getFleetList: `${fleetList}/FleetList`,
  getFleetListByName: `${fleetList}/FleetList`,
  getFleetListById: `${fleetList}`,
  deleteFleetById: `${fleetList}`,
  addFleetList: `${fleetList}/Create`,
  updateFleetList: `${fleetList}/Update`,
  fleetListVehicleInfo: `${fleetList}/FundingNewVehicleList`,
  multipleFleet: `${fleetList}/UpdateMultiple`,
  multipleFleetDelete: `${fleetList}/DeleteMultiple`,
};
apiUris.fleetListApi = fleetListApi;

const fuelList = `${baseUri}/Fuel`;
const fuelListApi = {
  getFuelList: `${fuelList}/List`,
  getFuelListById: `${fuelList}`,
  addFuelList: `${fuelList}/Create`,
  updateFueltList: `${fuelList}/Update`,
  deleteFuelListById: `${fuelList}`,
};
apiUris.fuelListApi = fuelListApi;

const fundList = `${baseUri}/FundingType`;
const fundListApi = {
  getFundList: `${fundList}/List`,
  getFundListById: `${fundList}`,
  addFundList: `${fundList}/Create`,
  updateFundtList: `${fundList}/Update`,
  deleteFundListById: `${fundList}`,
};
apiUris.fundListApi = fundListApi;

const typeList = `${baseUri}/Type`;
const typeListApi = {
  getTypeList: `${typeList}/List`,
  getTypeListById: `${typeList}`,
  addTypeList: `${typeList}/Create`,
  updateTypetList: `${typeList}/Update`,
};
apiUris.typeListApi = typeListApi;

const driveTrainList = `${baseUri}/DriveTrain`;
const driveTrainListApi = {
  getDriveTrainList: `${driveTrainList}/List`,
  getDriveTrainListById: `${driveTrainList}`,
  addDriveTrainList: `${driveTrainList}/Create`,
  updateDriveTraintList: `${driveTrainList}/Update`,
};
apiUris.driveTrainListApi = driveTrainListApi;

const replacementScheduleList = `${baseUri}/ReplacementSchedule`;
const replacementScheduleListApi = {
  getReplacementList: `${replacementScheduleList}/ReplacementScheduleList`,
  getReplacementListById: `${replacementScheduleList}`,
  addReplacementList: `${replacementScheduleList}/Create`,
  updateReplacementtList: `${replacementScheduleList}/Update`,
  deleteReplacementListById: `${replacementScheduleList}`,
};
apiUris.replacementScheduleListApi = replacementScheduleListApi;

const fuelAnalysisList = `${baseUri}/FuelAnalysis`;
const fuelAnalysisListApi = {
  getFuelAnalysisList: `${fuelAnalysisList}/List`,
  getFuelAnalysisById: `${fuelAnalysisList}`,
  addFuelAnalysisList: `${fuelAnalysisList}/Create`,
  updateFuelAnalysisList: `${fuelAnalysisList}/Update`,
  getListFuelAnalysisList: `${fuelAnalysisList}/FuelAnalysisList`,
  deleteFuelAnalysisList: `${fuelAnalysisList}`,
};
apiUris.fuelAnalysisListApi = fuelAnalysisListApi;

const stateList = `${baseUri}/State`;
const stateListApi = {
  getStateList: `${stateList}/List`,
  getStateById: `${stateList}`,
  addStateList: `${stateList}/Create`,
  updateStateList: `${stateList}/Update`,
};
apiUris.stateListApi = stateListApi;

const maintainanceList = `${baseUri}/Maintenance`;
const maintainanceListApi = {
  getMaintainanceList: `${maintainanceList}/MaintenanceList`,
  getMaintainanceById: `${maintainanceList}`,
  addMaintainanceList: `${maintainanceList}/Create`,
  updateMaintainanceList: `${maintainanceList}/Update`,
  deleteMaintainanceList: `${maintainanceList}`,
};
apiUris.maintainanceListApi = maintainanceListApi;

const analysisResultList = `${baseUri}/AnalysisResults`;
const analysisResultListApi = {
  getAnalysisResultList: `${analysisResultList}/List`,
  getAnalysisResultById: `${analysisResultList}`,
  addAnalysisResultList: `${analysisResultList}/Create`,
  updateAnalysisResultList: `${analysisResultList}/Update`,
  deleteAnalysisResultList: `${analysisResultList}`,
};
apiUris.analysisResultListApi = analysisResultListApi;

const fuelEconomy = `${baseUri}/FuelEconomy`;
const fuelEconomyApi = {
  getFuelEconomyList: `${fuelEconomy}/List`,
  getFuelEconomyById: `${fuelEconomy}`,
  addFuelEconomy: `${fuelEconomy}/Create`,
  updateFuelEconomy: `${fuelEconomy}/Update`,
  deleteFuelEconomy: `${fuelEconomy}`,
  fuelEconomyCreateMultiple: `${fuelEconomy}/CreateMultiple`,
};
apiUris.fuelEconomyApi = fuelEconomyApi;

const supportMail = `${baseUri}/Support`;
const supportMailApi = {
  support: `${supportMail}/SentEmailToSupportFromUser`,
};
apiUris.supportMailApi = supportMailApi;

const helpDesk = `${baseUri}/HelpDesk`;
const helpDeskApi = {
  getHelpDesk: `${helpDesk}/List`,
  createHelpDesk: `${helpDesk}/Create`,
  updateHelpDesk: `${helpDesk}/Update`,
  deleteHelpDesk: `${helpDesk}`,
};
apiUris.helpDeskApi = helpDeskApi;

const comments = `${baseUri}/Comments`;
const commentsApi = {
  getComments: `${comments}/List`,
  createComments: `${comments}/Create`,
  updateComments: `${comments}/Update`,
};
apiUris.commentsApi = commentsApi;

const userLogs = `${baseUri}/UserLogs`;
const userLogsApi = {
  getUserLogs: `${userLogs}/List`,
  createUserLogs: `${userLogs}/Create`,
  updateUserLogs: `${userLogs}/Update`,
  GetAllLoggedUserWithUserName: `${userLogs}/GetAllLoggedUserWithUserName`,
};
apiUris.userLogsApi = userLogsApi;

const fuelPrice = `${baseUri}/FuelPrice`;
const fuelPriceApi = {
  getFuelPrice: `${fuelPrice}/List`,
  dailyFuelPriceUpdate: `${fuelPrice}/DailyPriceData`,
};
apiUris.fuelPriceApi = fuelPriceApi;

export default apiUris;
