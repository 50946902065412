import { getData, postData } from "./base-api";
import ApiUris from "./apiUris";

export const fuelPriceApi = {
  getFuelPriceList(request) {
    return postData(ApiUris.fuelPriceApi.getFuelPrice, request);
  },
  getDailyPriceUpdate(id) {
    return getData(ApiUris.fuelPriceApi.dailyFuelPriceUpdate);
  },
};
