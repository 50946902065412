export const TypeName = {
  suv: "suv",
  sedan: "sedan",
  LD: "ld",
  MD: "md",
  HD: "hd",
  EV: "ev",
  VAN: "van",
};

export const LimitConstant = {
  limit: 60000,
};
