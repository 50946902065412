import moment from "moment/moment";
import { addComma, getCurrencyValue } from "../services/number-service";
import { getCellClass } from "./commonFunction";
import { SelectedModels } from "../../components/SelectedModels";
import { escapeSpecialChars } from "../utils/excelUpload";
import { color } from "framer-motion";

export const rowData = [
  {
    id: 1,
    name: "Hello1",
    age: "World1",
    year: "20031",
    make: "asa1",
    model: "123 cjhj1",
    fuel: "gas1",
    type: "online1",
    drivetrain: "2 wheel 1",
    cpmCategory: "standrd1",
    mpg: "231",
    vin: "123DFV344RFVC1",
    driverName: "--",
    totalMiles: "123,4441",
    annualMilege: "250001",
    monthlyMilege: "23441",
  },
  {
    id: 2,
    name: "Hello2",
    age: "World2",
    year: "20032",
    make: "asa2",
    model: "123 cjhj2",
    fuel: "gas2",
    type: "online2",
    drivetrain: "2 wheel 2",
    cpmCategory: "standrd2",
    mpg: "232",
    vin: "123DFV344RFGRVC2",
    driverName: "--",
    totalMiles: "123,4442",
    annualMilege: "250002",
    monthlyMilege: "23442",
  },
  {
    id: 3,
    name: "Hello",
    age: "World",
    year: "2003",
    make: "asa",
    model: "123 cjhj",
    fuel: "gas",
    type: "online",
    drivetrain: "2 wheel ",
    cpmCategory: "standrd",
    mpg: "23",
    vin: "123DFV344RFVC",
    driverName: "--",
    totalMiles: "123,444",
    annualMilege: "25000",
    monthlyMilege: "2344",
  },
];

export const columnData = [
  {
    field: "fleetNo",
    headerName: "Name ",
    headerClassName: "super-app-theme--header",
    cellStyle: (params) => getColorChange(params),
  },
  {
    field: "age",
    headerName: "Age ",
    headerClassName: "super-app-theme--header",
    cellStyle: (params) => getColorChange(params),
  },
  {
    field: "year",
    headerName: "Year ",
    headerClassName: "super-app-theme--header",
    cellStyle: (params) => getColorChange(params),
  },
  {
    field: "make",
    headerName: "Make ",
    headerClassName: "super-app-theme--header",
    cellStyle: (params) => getColorChange(params),
  },
  {
    field: "originalModel",
    headerName: "Original Model",
    headerClassName: "super-app-theme--header",
    cellStyle: (params) => getColorChange(params),
  },
  {
    field: "model",
    headerName: "Selected Model",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "fuel",
    headerName: "Fuel ",
    headerClassName: "super-app-theme--header",
    cellStyle: (params) => getColorChange(params),
  },
  {
    field: "type",
    headerName: "Type ",
    headerClassName: "super-app-theme--header",
    cellStyle: (params) => getColorChange(params),
  },
  // {
  //   field: "driveTrain",
  //   headerName: "Drive Train ",
  //   headerClassName: "super-app-theme--header",
  //   cellStyle: (params) => getColorChange(params),
  // },
  {
    field: "cpmCategory",
    headerName: "CPM Category ",
    headerClassName: "super-app-theme--header",
    cellStyle: (params) => getColorChange(params),
  },
  {
    field: "mpg",
    headerName: "MPG ",
    headerClassName: "super-app-theme--header",
    cellStyle: (params) => getColorChange(params),
  },
  // {
  //   field: "groupType",
  //   headerName: "Group Type",
  //   headerClassName: "super-app-theme--header",
  //   cellStyle: (params) => getColorChange(params),
  // },
  {
    field: "vin",
    headerName: "VIN ",
    headerClassName: "super-app-theme--header",
    cellStyle: (params) => getColorChange(params),
  },
  {
    field: "drivername",
    headerName: "Driver Name ",
    headerClassName: "super-app-theme--header",
    cellStyle: (params) => getColorChange(params),
  },
  {
    field: "totalMiles",
    headerName: "Total Miles ",
    headerClassName: "super-app-theme--header",
    cellStyle: (params) => getColorChange(params),
    valueFormatter: function (params) {
      return addComma(Math.round(params?.value));
    },
  },
  {
    field: "annualMileage",
    headerName: "Annual Mileage ",
    headerClassName: "super-app-theme--header",
    cellStyle: (params) => getColorChange(params),
    valueFormatter: function (params) {
      return addComma(Math.round(params?.value));
    },
  },
  {
    field: "monthlyMileage",
    headerName: "Monthly Mileage ",
    headerClassName: "super-app-theme--header",
    cellStyle: (params) => getColorChange(params),
    valueFormatter: function (params) {
      return addComma(Math.round(params?.value));
    },
  },
  {
    field: "state",
    headerName: "Location",
    headerClassName: "super-app-theme--header",
    cellStyle: (params) => getColorChange(params),
  },
];
export const fuelPriceColumnData = [
  {
    field: "state",
    headerName: "State",
    minWidth: 160,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "regularPrice",
    headerName: "Regular Price",
    minWidth: 160,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "midGradePrice",
    headerName: "Mid-Grade Price",
    minWidth: 160,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "premiumPrice",
    headerName: "Premium Price",
    minWidth: 160,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "dieselPrice",
    headerName: "Diesel Price",
    minminWidth: 160,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "updatedAt",
    headerName: "Fuel Price Date",
    minminWidth: 160,
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return moment(params?.value).format("MM/DD/YYYY");
    },
  },
];

export const companiesColumnData = [
  {
    field: "companyName",
    headerName: "Company Name",
    minWidth: 160,
    headerClassName: "super-app-theme--header",
  },
];

export const companiesRowData = [
  {
    id: 1,
    name: "Demo-cars",
  },
  {
    id: 2,
    name: "Demo-cars1",
  },
  {
    id: 3,
    name: "Demo-cars2",
  },
  {
    id: 4,
    name: "Demo-cars3",
  },
];

export const fuelEconomyColumn = [
  {
    field: "make",
    headerName: "Make",
    minWidth: 200,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "model",
    headerName: "Model",
    minWidth: 200,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "year",
    headerName: "Year",
    minWidth: 200,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "mpg",
    headerName: "MPG",
    minWidth: 200,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "id",
    headerName: "Government ID",
    minWidth: 200,
    headerClassName: "super-app-theme--header",
  },
];
export const rowFuelEconomyData = [
  { id: 1, make: "US", model: 12, year: 12, mpg: 12, government_id: 12 },
  { id: 2, make: "TH", model: 13, year: 1, mpg: 2, government_id: 3 },
  { id: 3, make: "US", model: 12, year: 12, mpg: 12, government_id: 12 },
  { id: 4, make: "TH", model: 13, year: 1, mpg: 2, government_id: 3 },
  { id: 5, make: "US", model: 12, year: 12, mpg: 12, government_id: 12 },
  { id: 6, make: "TH", model: 13, year: 1, mpg: 2, government_id: 3 },
  { id: 7, make: "US", model: 12, year: 12, mpg: 12, government_id: 12 },
  { id: 8, make: "TH", model: 13, year: 1, mpg: 2, government_id: 3 },
  { id: 9, make: "US", model: 12, year: 12, mpg: 12, government_id: 12 },
  { id: 10, make: "TH", model: 13, year: 1, mpg: 2, government_id: 3 },
  { id: 11, make: "US", model: 12, year: 12, mpg: 12, government_id: 12 },
  { id: 12, make: "TH", model: 13, year: 1, mpg: 2, government_id: 3 },
  { id: 13, make: "US", model: 12, year: 12, mpg: 12, government_id: 12 },
  { id: 14, make: "TH", model: 13, year: 1, mpg: 2, government_id: 3 },
];

export const makeColumnData = [
  {
    field: "name",
    headerName: "Name",
    minWidth: 160,
    headerClassName: "super-app-theme--header",
  },
];

export const modelColumnData = [
  {
    field: "model",
    headerName: "Name",
    minWidth: 160,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "make",
    headerName: "Makes",
    minWidth: 160,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "year",
    headerName: "Year",
    minWidth: 160,
    headerClassName: "super-app-theme--header",
  },
];
export const yearColumnData = [
  {
    field: "year",
    headerName: "Year",
    minWidth: 160,
    headerClassName: "super-app-theme--header",
  },
];
export const dealerColumnData = [
  {
    field: "dealerName",
    headerName: "Dealer Name",
    minWidth: 160,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "companies",
    headerName: "Allow Companies",
    minWidth: 160,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "address",
    headerName: "Address",
    minWidth: 160,
    headerClassName: "super-app-theme--header",
  },
  // {
  //   field: "location",
  //   headerName: "Location",
  //   minWidth: 160,
  //   headerClassName: "super-app-theme--header",
  // },
  {
    field: "status",
    headerName: "Status",
    minWidth: 160,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "region",
    headerName: "Region",
    minWidth: 160,
    headerClassName: "super-app-theme--header",
  },
];
export const dealerCompanyColumnData = [
  {
    field: "dealerName",
    headerName: "Dealer Name",
    minWidth: 160,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "companyName",
    headerName: "Company Name",
    minWidth: 160,
    headerClassName: "super-app-theme--header",
  },
];

export const ManageUserrowData = [
  {
    id: 1,
    name: "John",
    email: "test123@gmail.com",
    username: "john12",
    password: "john@12",
    phone: "9087654321",
    type: "fuel",
  },
  {
    id: 2,
    name: "Doe",
    email: "doe123@gmail.com",
    username: "doe12",
    password: "doe@12",
    phone: "9087623211",
    type: "fuel",
  },
];

export const ManageUserColumnData = [
  {
    field: "name",
    headerName: "Name",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "email",
    headerName: "Email",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "userName",
    headerName: "Username",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "password",
    headerName: "Password",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "phone",
    headerName: "Phone",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "type",
    headerName: "Type",
    headerClassName: "super-app-theme--header",
    cellClass: "TypeClass",
  },
];

export const FuelAnalysiscolumnData = [
  {
    field: "fleetNo",
    headerName: "Fleet List ",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "fuelPriceLocation",
    headerName: "Fuel Price Location ",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "date",
    headerName: "Fuel Price Date ",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return params?.value
        ? moment(params.value).format("MM/DD/YYYY HH:mm:ss")
        : "";
    },
  },
  // {
  //   field: "fuelCompanyName",
  //   headerName: "Company Name ",
  //   headerClassName: "super-app-theme--header",
  // },
  {
    field: "year",
    headerName: "Year ",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "age",
    headerName: "Age ",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "model",
    headerName: "Model ",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "annualMileage",
    headerName: "Vehicle Annual Mileage ",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return addComma(Math.round(params?.value));
    },
  },
  {
    field: "averageFuelPrice",
    headerName: "Average Fuel Price ",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "totalMiles",
    headerName: "Vehicle Total Mileage ",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return addComma(Math.round(params?.value));
    },
  },
  {
    field: "originalFuelEconomy",
    headerName: "Original Fuel Economy ",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "cyclingFuelEconomy",
    headerName: "Cycling Fuel Economy ",
    headerClassName: "super-app-theme--header",
  },
  // {
  //   field: "groupType",
  //   headerName: "Group Type",
  //   headerClassName: "super-app-theme--header",
  // },
];

export const FundingNewVehicleColumnData = [
  {
    field: "fleetNo",
    headerName: "Fleet No",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "year",
    headerName: "Year ",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "make",
    headerName: "Make ",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "model",
    headerName: "Model ",
    headerClassName: "super-app-theme--header",
  },
  // {
  //   field: "groupType",
  //   headerName: "Group Type",
  //   headerClassName: "super-app-theme--header",
  // },
  {
    field: "newYear",
    headerName: "New Vehicle Year ",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "newMake",
    headerName: "New Vehicle Make ",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "newModel",
    headerName: "New Vehicle Model ",
    headerClassName: "super-app-theme--header",
  },
  // do not remove the below commented code
  // {
  //   field: "newFuel",
  //   headerName: "Fuel",
  //   headerClassName: "super-app-theme--header",
  // },
  {
    field: "newMpg",
    headerName: "New MPG",
    headerClassName: "super-app-theme--header",
  },
  // {
  //   field: "newFuel",
  //   headerName: "New Fuel",
  //   headerClassName: "super-app-theme--header",
  // },
  {
    field: "paymentAmount",
    headerName: "Payment Amount ",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "fundingType",
    headerName: "Funding Type",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "newVehicleCost",
    headerName: "New Vehicle Cost ",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "newEstimatedResaleValue",
    headerName: "New Estimated Resale Value ",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "residualPercentage",
    headerName: "Residual % ",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "equityGain",
    headerName: "Equity Gain",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
];

export const replacementScheduleColumnData = [
  {
    field: "fleetNo",
    minWidth: 100,
    headerName: "Fleet",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "age",
    minWidth: 70,
    headerName: "Age ",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "totalMiles",
    minWidth: 100,
    headerName: "Vehicle Total Miles",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return addComma(Math.round(params?.value));
    },
  },
  {
    field: "annualMileage",
    minWidth: 100,
    headerName: "Vehicle Annual Mileage ",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return addComma(Math.round(params?.value));
    },
  },
  // {
  //   field: "groupType",
  //   minWidth: 90,
  //   headerName: "Group Type",
  //   headerClassName: "super-app-theme--header",
  // },
  {
    field: "year1",
    minWidth: 90,
    headerName: "Year 1",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "year2",
    minWidth: 90,
    headerName: "Year 2",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "year3",
    minWidth: 90,
    headerName: "Year 3",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "year4",
    minWidth: 90,
    headerName: "Year 4",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "year5",
    minWidth: 90,
    headerName: "Year 5",
    headerClassName: "super-app-theme--header",
  },
];

export const maintenanceAnalysisColumnData = [
  {
    field: "fleetNo",
    headerName: "Fleet No",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "totalMiles",
    headerName: "Vehicle Total Miles",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return addComma(Math.round(params?.value));
    },
  },
  {
    field: "annualMileage",
    headerName: "Vehicle Annual Mileage ",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return addComma(Math.round(params?.value));
    },
  },
  // {
  //   field: "groupType",
  //   headerName: "Group Type",
  //   headerClassName: "super-app-theme--header",
  // },
  {
    field: "currentCostPerMile",
    headerName: "Current Cost Per Mile",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "currentYearTotalCost",
    headerName: "Current Year Total Cost",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "year_1_CostPerMile",
    headerName: "1st Year Cost Per Mile",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "year_1_TotalCost",
    headerName: "1st Year Total Cost",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "year_2_CostPerMile",
    headerName: "2nd Year Cost Per Mile",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "year_2_TotalCost",
    headerName: "2nd Year Total Cost",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "year_3_CostPerMile",
    headerName: "3rd Year Cost Per Mile",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "year_3_TotalCost",
    headerName: "3rd Year Total Cost",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "year_4_CostPerMile",
    headerName: "4th Year Cost Per Mile",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "year_4_TotalCost",
    headerName: "4th Year Total Cost",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "year_5_CostPerMile",
    headerName: "5th Year Cost Per Mile",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "year_5_TotalCost",
    headerName: "5th Year Total Cost",
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
];

export const analysisReportColumnData = [
  {
    field: "fiscalYear",
    headerName: "Fiscal Year",
    minWidth: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "fleetSize",
    headerName: "Fleet Size",
    minWidth: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "annualNeeds",
    headerName: "Annual Needs",
    minWidth: 100,
    headerClassName: "super-app-theme--header",
    editable: (params) => params.node.rowIndex === 0,
    cellClass: (params) => getCellClass("annualNeeds", params),
  },
  {
    field: "purchasedFinancial",
    headerName: "Purchased/ Financed",
    minWidth: 100,
    headerClassName: "super-app-theme--header",
    cellEditor: "agNumberCellEditor",
    editable: true,
    cellClass: (params) => getCellClass("purchasedFinancial", params),
  },
  {
    field: "cyclingPayment",
    headerName: "Cycling Payment",
    minWidth: 100,
    headerClassName: "super-app-theme--header",
    valueFormatter: (params) => getCurrencyValue(params?.value),
  },
  {
    field: "cashOutlay",
    headerName: "Cash Outlay",
    minWidth: 100,
    headerClassName: "super-app-theme--header",
    cellEditor: "agNumberCellEditor",
    editable: (params) => (params.node.rowIndex === 0 ? false : true),
    cellClass: (params) => getCellClass("cashOutlay", params),
    valueFormatter: (params) => getCurrencyValue(params?.value),
  },
  {
    field: "currentPayment",
    headerName: "Current Payment",
    minWidth: 100,
    headerClassName: "super-app-theme--header",
    cellEditor: "agNumberCellEditor",
    editable: true,
    cellClass: (params) => getCellClass("currentPayment", params),
    valueFormatter: (params) => getCurrencyValue(params?.value),
  },
  {
    field: "maintenance",
    headerName: "Maintenance",
    minWidth: 100,
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "fuel",
    headerName: "Fuel",
    minWidth: 100,
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "fleetSpeed",
    headerName: "Fleet Spend",
    minWidth: 100,
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "vehicleEquity",
    headerName: "Vehicle Equity",
    minWidth: 100,
    headerClassName: "super-app-theme--header",
    editable: true,
    cellEditor: "agNumberCellEditor",
    cellClass: (params) => getCellClass("vehicleEquity", params),
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "savings",
    headerName: "Savings",
    minWidth: 100,
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
];

export const ReportColumnData = [
  {
    field: "fiscalYear",
    headerName: "Fiscal Year",
    minWidth: 100,
    sort: "asc",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "fleetSize",
    headerName: "Fleet Size",
    minWidth: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "annualNeeds",
    headerName: "Annual Needs",
    minWidth: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "purchasedFinancial",
    headerName: "Purchased/ Financed",
    minWidth: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "cyclingPayment",
    headerName: "Cycling Payment",
    minWidth: 100,
    headerClassName: "super-app-theme--header",
    valueFormatter: (params) => getCurrencyValue(params?.value),
  },
  {
    field: "cashOutlay",
    headerName: "Cash Outlay",
    minWidth: 100,
    headerClassName: "super-app-theme--header",
    valueFormatter: (params) => getCurrencyValue(params?.value),
  },
  {
    field: "currentPayment",
    headerName: "Current Payment",
    minWidth: 100,
    headerClassName: "super-app-theme--header",
    valueFormatter: (params) => getCurrencyValue(params?.value),
  },
  {
    field: "maintenance",
    headerName: "Maintenance",
    minWidth: 110,
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "fuel",
    headerName: "Fuel",
    minWidth: 110,
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "fleetSpeed",
    headerName: "Fleet Spend",
    minWidth: 110,
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "vehicleEquity",
    headerName: "Vehicle Equity",
    minWidth: 110,
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
  {
    field: "savings",
    headerName: "Savings",
    minWidth: 110,
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return getCurrencyValue(params?.value);
    },
  },
];

export const analysisReportData = [
  {
    field: "companyId",
    headerName: "User Name",
    minWidth: 160,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "reportName",
    headerName: "Report Name",
    minWidth: 160,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "createdAt",
    headerName: "DateTime",
    minWidth: 160,
    headerClassName: "super-app-theme--header",
    valueFormatter: function (params) {
      return params?.value
        ? moment(params.value).format("MM/DD/YYYY HH:mm:ss")
        : "";
    },
  },
];

function numberValueParser(params) {
  return Number(params.newValue);
}
function CheckIsModel(params) {
  if (params.value === false) {
    return "No";
  } else if (params.value) {
    return "Yes";
  }
}

export const UploadCsvDataColumn = [
  {
    field: "year",
    headerName: "Year",
    headerClassName: "super-app-theme--header",
    cellEditor: "agNumberCellEditor",
    valueParser: numberValueParser,
    editable: true,
    minWidth: 90,
  },
  {
    field: "totalMiles",
    headerName: "Total Miles",
    headerClassName: "super-app-theme--header",
    cellEditor: "agNumberCellEditor",
    valueParser: numberValueParser,
    editable: true,
  },
  {
    field: "make",
    headerName: "Make ",
    headerClassName: "super-app-theme--header",
    editable: true,
  },
  {
    field: "model",
    headerName: "Model ",
    headerClassName: "super-app-theme--header",
    editable: true,
  },
  {
    headerName: "Closest Model",
    field: "language",
    headerClassName: "super-app-theme--header",
    // editable: true,
    cellRenderer: SelectedModels,
    cellEditor: "agRichSelectCellEditor",
    cellEditorParams: {
      values: ["Male", "Female"],
      cellRenderer: SelectedModels,
    },
  },
  {
    headerName: "Available Model",
    field: "selectedModelRow",
    headerClassName: "super-app-theme--header",
    cellRenderer: CheckIsModel,
    cellStyle: (params) => getFleetColorChange(params),
  },
  {
    field: "vin",
    headerName: "VIN ",
    headerClassName: "super-app-theme--header",
    editable: true,
  },
  {
    field: "stateCode",
    headerName: "State Code",
    headerClassName: "super-app-theme--header",
    editable: true,
    minWidth: 90,
  },
  {
    field: "dealer",
    headerName: "Dealer",
    headerClassName: "super-app-theme--header",
    editable: true,
  },
  {
    field: "company",
    headerName: "Company",
    headerClassName: "super-app-theme--header",
    editable: true,
    valueFormatter: (params) => escapeSpecialChars(params?.value),
  },
  // {
  //   field: "driveTrain",
  //   headerName: "Drive Train",
  //   headerClassName: "super-app-theme--header",
  //   editable: true,
  //   minWidth: 90,
  // },
  {
    field: "fuel",
    headerName: "Fuel",
    headerClassName: "super-app-theme--header",
    editable: true,
  },
  {
    field: "type",
    headerName: "Type",
    headerClassName: "super-app-theme--header",
    editable: true,
  },
  {
    field: "driverName",
    headerName: "Driver Name",
    headerClassName: "super-app-theme--header",
    editable: true,
  },
];

export const UploadCsvMpgDataColumn = [
  {
    field: "mpg",
    headerName: "Mpg",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "make",
    headerName: "Make ",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "model",
    headerName: "Model ",
    headerClassName: "super-app-theme--header",
  },
  {
    field: "year",
    headerName: "Year ",
    headerClassName: "super-app-theme--header",
  },
  // {
  //   field: "governmentId",
  //   headerName: "Government ID",
  //   headerClassName: "super-app-theme--header",
  // },
];

export const HelpDeskDataColumn = [
  { headerName: "ID", field: "id" },
  { headerName: "Name", field: "name" },
  { headerName: "Date", field: "date" },
  { headerName: "Image", field: "image" },
  { headerName: "Description", field: "description" },
  { headerName: "Status", field: "status" },
];

export const UserLogsColumn = [
  { headerName: "ID", field: "id" },
  { headerName: "User Name", field: "userName" },
  { headerName: "Start Date", field: "startDate" },
  { headerName: "End Date", field: "endDate" },
  { headerName: "Idle Time (Minutes)", field: "totalHours" },
];

export const getColorChange = (params) => {
  if (params.data.color !== "") {
    return { background: "#F77479", color: "black" };
  } else if (params.data.modelDifference) {
    return { background: "#F4F5A2", color: "black" };
  } else {
    return "";
  }
};

export const getFleetColorChange = (params) => {
  if (params.value) {
    return { background: "rgb(166 231 171)", color: "black" };
  } else if (params.value == false) {
    return { background: "rgb(239 208 180)", color: "black" };
  }
};
