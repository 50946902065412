import React, { useState, createContext, useCallback } from "react";
import { CompanyAPi, fleetListApi } from "../api";
import { dealerApi } from "../api/dealer-api";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../components/Toast";
import { useFleetList } from "../hooks";
import { TAB_CONTENT, TAB_VALUE } from "../constants/popupMessage";
// import { useCompany } from "../hooks";

/**
 * Authentication context
 */
export const DealerContext = createContext();

/**
 * Authentication data provider
 */
export const DealerProvider = ({ children }) => {
  const [dealer, setDealer] = useState([]);
  const [dealerList, setDealerList] = useState();
  const [dealerById, setDealerById] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingFleetFile, setLoadingFleetFile] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [fleetUpload, setFleetUpload] = useState(false);
  const [dealerId, setDealerId] = useState(null);
  const [companyDealerId, setCompanyDealerId] = useState(null);
  const [newFleetNo, setNewFleetNo] = useState(null);
  const [autoCalculation, setAutoCalculation] = useState([]);
  const navigation = useNavigate();
  // const { getCompanyByid } = useCompany()

  const getDealerApi = async (values) => {
    setLoading(true);
    const response = await dealerApi.getDealer({
      limit: values?.limit ? values.limit : 10000,
      offset: values?.offset ? values.offset : 0,
      filter: {
        ...values?.filter,
      },
      sorting: {
        fieldName: "dealerName",
        direction: "asc",
      },
    });
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      setDealer(response?.data?.data);
      setLoading(false);
      setFleetUpload(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };
  const getListDealerApi = async (values) => {
    setLoading(true);
    const response = await dealerApi.getListDealer({
      limit: values?.limit ? values.limit : 10000,
      offset: values?.offset ? values.offset : 0,
      filter: {
        ...values?.filter,
      },
      ...values,
      sorting: {
        fieldName: "",
        direction: "",
      },
      // dealerId: values,
    });
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      setDealerList(response?.data?.data);
      // getCompanyByid(response?.data?.data?.map(item => item.companyId))
      setLoading(false);
    } else {
      setDealerList([]);
      setLoading(false);
    }

    return response.data;
  };
  const getDealerByIdApi = async (id) => {
    setLoading(true);
    const response = await dealerApi.getDealerByid(id);

    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      setDealerById(response?.data?.data);
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };
  const deleteDealerByIdApi = async (id) => {
    setLoading(true);
    const response = await dealerApi.deleteDealerByid(id);

    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      getDealerApi();
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };
  const createDealerApi = async (values) => {
    setLoading(true);
    const response = await dealerApi.createDealer(values);

    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      setDealer(response?.data?.data);
      getDealerApi();
    } else {
      if (response.data.messages.length > 0) {
        setErrorMessage(response.data.messages[0]);
        setLoading(false);
      }
    }

    return response.data;
  };
  const editDealerApi = async (values) => {
    setLoading(true);
    const response = await dealerApi.editDealer(values);
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      // getDealerApi();
      getDealerApi();
      setLoading(false);
    } else {
      setErrorMessage(response.data.messages[0]);
      setLoading(false);
    }

    return response.data;
  };
  //   const addCompanyApi = async (values) => {
  //     // showLoading(true);
  //     const response = await CompanyAPi.createCompany({...values});

  //     if (response?.error) {
  //     //   addMessage({ message: response.error.response.error, type: "error" });
  //       // return true;
  //     }

  //     if (response?.data?.data) {
  //         await getCompanyApi()
  //     } else {
  //     }

  //     return response.data;
  //   };
  //   const updateCompany = async (values) => {
  //     // showLoading(true);
  //     const response = await CompanyAPi.updateCompany({...values});

  //     if (response?.error) {
  //     //   addMessage({ message: response.error.response.error, type: "error" });
  //       // return true;
  //     }

  //     if (response?.data?.data) {
  //         await getCompanyApi()
  //     } else {
  //     }

  //     return response.data;
  //   };
  const uploadCSVDealerApi = async (values, company) => {
    setLoadingFleetFile(true);
    const response = await dealerApi.uploadCSVDealer(values);
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoadingFleetFile(false);
    }
    if (response?.data?.succeeded) {
      setErrorMessage("empty");
      if (response?.data?.data?.calculatedFleets?.length == 0) {
        showToast(response?.data?.data?.message);
      } else if (response?.data?.data?.message) {
        showToast(response?.data?.data?.message);
      } else {
        showToast("Searching data from Government Website. Please wait.");
        setDealerId(response?.data?.data?.dealerId);
        setCompanyDealerId(response?.data?.data?.companyId);
        setDealerList(response?.data?.data);
        await getDealerApi({ companyId: company });
        setLoadingFleetFile(false);
      }
      if (response?.data?.data?.dealerId && response?.data?.data?.companyId) {
        setDealerId(response?.data?.data?.dealerId);
        setCompanyDealerId(response?.data?.data?.companyId);
      }
      setFleetUpload(true);
      setLoadingFleetFile(false);
    } else if (response?.data?.messages) {
      showToast(response.data.messages[0]);
      setFleetUpload(false);
    } else {
      setDealerList([]);
      setFleetUpload(false);
      setLoadingFleetFile(false);
    }

    return response.data;
  };
  const updateFleetApi = async (cavUpload, createPayload) => {
    const response = await dealerApi.uploadCSVDealer({ data: [cavUpload] });
    if (response?.error) {
      setLoadingFleetFile(false);
    }
    if (response?.data?.data?.calculatedFleets?.length > 0) {
      let fleetNo = response?.data?.data?.calculatedFleets[0]?.fleet?.fleetNo;
      let id = response?.data?.data?.calculatedFleets[0]?.fleet?.id;
      let fleetUpdate = await fleetListApi.editFleetList({
        fleetNo: fleetNo,
        id: id,
        ...createPayload,
      });
      await dealerApi.singleFleetUpdate({
        fleetNo: fleetNo,
        company: cavUpload?.company,
        dealer: cavUpload?.dealer,
        fuelId: createPayload?.fuelId,
        make: cavUpload?.make,
        model: cavUpload?.model,
        mpg: createPayload?.mpg,
        stateCode: cavUpload?.stateCode,
        totalMiles: createPayload?.totalMiles,
        typeId: createPayload?.typeId,
        vin: createPayload?.vin,
        year: cavUpload?.year,
      });
      setNewFleetNo(fleetNo);
      if (fleetUpdate?.data?.succeeded) {
        navigation("/new-fleetlist", {
          state: {
            tabValue: TAB_VALUE.FLEET_LIST,
            selectedContent: TAB_CONTENT.FLEET_LIST,
            data: { ...createPayload, fleetNo: fleetNo, fleetId: id },
          },
        });
        showToast("Fleet Created Successfully!");
      } else {
        showToast("Failed to Create Fleet!");
      }
    } else if (response?.data?.messages) {
      showToast(response.data.messages[0]);
    } else {
    }

    return response.data;
  };
  const multipleFleetUpdateDealerApi = async (values, company) => {
    setLoading(true);
    const response = await dealerApi.multipleFleetUpdate([...values]);
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };

  const singleFleetUpdateDealerApi = async (values, company) => {
    setLoading(true);
    const response = await dealerApi.singleFleetUpdate(values);
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };

  const manualCalculationApi = async (values, company) => {
    setLoading(true);
    const response = await dealerApi.manualCalculationUpdate(values);
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };
  const multipleManualCalculationApi = async (values, company) => {
    setLoading(true);
    const response = await dealerApi.multipleManualCalculationUpdate(values);
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      setLoading(false);
    } else {
      setLoading(false);
    }

    return response.data;
  };
  const autoMultipleManualCalculationApi = async (values, company) => {
    setLoading(true);
    const response = await dealerApi.autoMultipleManualCalculationUpdate(
      values
    );
    if (response?.error) {
      //   addMessage({ message: response.error.response.error, type: "error" });
      // return true;
      setLoading(false);
    }

    if (response?.data?.data) {
      setAutoCalculation(response?.data?.data);
      setLoading(false);
    } else {
      setLoading(false);
      setAutoCalculation([]);
    }

    return response.data;
  };
  const contextValue = {
    dealer,
    setDealer,
    loading,
    autoCalculation,
    setAutoCalculation,
    setLoading,
    setDealerById,
    dealerById,
    dealerList,
    setDealerList,
    errorMessage,
    fleetUpload,
    setFleetUpload,
    setErrorMessage,
    dealerId,
    newFleetNo,
    setNewFleetNo,
    setDealerId,
    companyDealerId,
    setCompanyDealerId,
    loadingFleetFile,
    setLoadingFleetFile,
    getDealerApi: useCallback((request) => {
      getDealerApi(request);
    }, []),
    getListDealerApi: useCallback((request) => {
      getListDealerApi(request);
    }, []),
    updateFleetApi: useCallback((cavUpload, createPayload) => {
      updateFleetApi(cavUpload, createPayload);
    }, []),
    createDealerApi: useCallback((request) => {
      createDealerApi(request);
    }, []),
    editDealerApi: useCallback((request) => {
      editDealerApi(request);
    }, []),
    getDealerByIdApi: useCallback((request) => {
      getDealerByIdApi(request);
    }, []),
    deleteDealerByIdApi: useCallback((request) => {
      deleteDealerByIdApi(request);
    }, []),
    uploadCSVDealerApi: useCallback((request) => {
      uploadCSVDealerApi(request);
    }, []),
    multipleFleetUpdate: useCallback((request) => {
      multipleFleetUpdateDealerApi(request);
    }, []),
    singleFleetUpdateDealerApi: useCallback((request) => {
      singleFleetUpdateDealerApi(request);
    }, []),
    manualCalculationApi: useCallback((request) => {
      manualCalculationApi(request);
    }, []),
    multipleManualCalculationApi: useCallback((request) => {
      multipleManualCalculationApi(request);
    }),
    autoMultipleManualCalculationApi: useCallback((request) => {
      autoMultipleManualCalculationApi(request);
    }),
  };

  return (
    <DealerContext.Provider value={contextValue}>
      {children}
    </DealerContext.Provider>
  );
};
